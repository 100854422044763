import React, { useContext } from 'react'
import { Context as BlogContext } from '../context/BlogContext'
import BlogPostForm from './BlogPostForm'
// import { Configuration, OpenAIApi } from 'openai';


export default function CreateBlog({open, handleClose, newBlogType, customHeader}) {
    const { state, addBlogPost }  = useContext(BlogContext)
    // const configuration = new Configuration({
    //   apiKey: process.env.REACT_APP_OPEN_API,
    // });

//     const openai = new OpenAIApi(configuration);
//     const response = await openai.createCompletion({
//       model: "text-davinci-003",
//       prompt: "Say this is a test",
//       temperature: 0,
//       max_tokens: 7,
//     });
    
    return open && <BlogPostForm
                state={state}
                blogPost={{title:'',description:'', url:'',authorName:''}}
                open={open} 
                handleClose={handleClose}
                onSubmit={addBlogPost}
                header="Create New Blog"
        />
}
