import React,{ useContext, useEffect, Fragment, useState } from 'react'
import { Grid} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import BlogContent from './BlogContent';
import { Context as BlogContext } from '../context/BlogContext'

import NoBlogText from '../common/NoBlogText';
import Portal from '../common/Portal';
import ToastMessage from '../common/ToastMessage';

const useStyles = makeStyles((theme) => ({
    gridContainerStyle:{
        display: 'flex' , 
        justifyContent:'space-evenly'
    }
}))

function BlogsList() {
    const classes = useStyles()
    const { state , getBlogPost, setDonationSuccessfull } = useContext(BlogContext)
    const [ isLoading , setIsLoading ] = useState(true)

    useEffect(()=>{
        if(state?.isDonationSuccessful){
            setTimeout(() => {
                setDonationSuccessfull(false)
            }, 1000);
        }
    },[state]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getBlogPost()
        setTimeout(()=>{
            setIsLoading(false)
        }, 3000)
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const renderList = () => {
        if(state.blogDetails !== undefined){
            return state?.blogDetails.map((blog, index) =>{
                return <Fragment key={index}>
                        <BlogContent 
                            title={blog.title}
                            image={blog.url}
                            description={blog.description}
                            authorName={blog.authorName}
                            uid={blog.uid}
                            createdDate={blog.createdDate}
                            isFav={blog.isFavorite || false }
                            isLoading={isLoading}
                        />
                    </Fragment>
            })
        }else if(state?.blogDetails?.length === 0 && !isLoading ) {
            return <Portal isOpen={true}><NoBlogText header="blogs"/></Portal> 
        }
    }
    return (
        <>
         <Grid container item className={classes.gridContainerStyle}>
            {renderList()}
        </Grid>
            <ToastMessage isSuccess={state?.isDonationSuccessful} />
        </>
       
    )
}

export default BlogsList
