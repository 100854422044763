
import React, {useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardContent, CardActions, IconButton, Typography, Button} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import DeleteBlogDialog from './DeleteBlogDialog';
import { Context as BlogContext } from '../context/BlogContext'
import '../styles/blogContent.css'
import ReadMoreDialog from './ReadMoreDialog';
import EditBlog from './EditBlog'
import CardHeader from '../common/CardHeader';
import { blogAnalytics } from '../common/Analytics';
import { Skeleton } from "@material-ui/lab";
import { Interweave } from 'interweave';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 330,
        width: 330,
        margin:'5px'
    },
    media: {
        height: 'auto',
        minHeight:'180px',
    },
    contentContainer:{
        padding:'4px 8px 0px 8px',
        height: '78px'
    },
    bottomContainer:{
        padding:'0px 8px'
    },
    bottomButtons: {
        marginLeft: 'auto',
        padding:'4px 0px',
        height:'34px'
    }
}));

export default function BlogContent({ title, description, image, authorName, uid, createdDate, isFav, isLoading}) {
    const classes = useStyles();
    const { favoriteBlog } = useContext(BlogContext) 
    const [isFavorite, setIsFavorite] = useState(isFav);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openReadMore, setOpenReadMore] = useState(false);

    const handleClickReadMore = () => {
        setOpenReadMore(true);
        blogAnalytics.trackEvent("READ MORE", "Read more blog", "READ_MORE_OPEN")
    };

    const handleCloseReadMore = () => {
        setOpenReadMore(false);
        blogAnalytics.trackEvent("READ MORE", "Read more blog", "READ_MORE_CLOSED")
    };

    const handleDeleteBlog = () => {
        setOpen(true);
        blogAnalytics.trackEvent("DELETE", "Delete modal open", "DELETE_OPEN")
    };
    
    const handleCloseDelete = () => {
        setOpen(false);
        blogAnalytics.trackEvent("DELETE", "Delete modal close", "DELETE_CLOSED")
    };

    const handleOpenEdit = () => {
        setOpenEdit(true);
        blogAnalytics.trackEvent("EDIT", "Edit modal open", "EDIT_BLOG_OPEN")
    };

    const handleCloseEdit = () => {
        setOpenEdit(false)
        blogAnalytics.trackEvent("EDIT", "Edit modal close", "EDIT_BLOG_CLOSED")
    }

    const handleFavorite = () => {
        setIsFavorite(!isFavorite)
        blogAnalytics.trackEvent("FAVORITE CLICKED ", "Favorite button clicked", `Favorite is ${!isFavorite}`)
    }

    useEffect(()=>{
        favoriteBlog({uid , isFavorite })
    },[isFavorite])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card className={`${classes.root} animate-vlogList`}  raised={true}>
            {
                isLoading ? 
                <div style={{width:'100%'}}>
                    <div style={{width:'100%', display:'flex'}}>
                        <Skeleton style={{ margin:'3px'}} animation="wave" variant="circle" height={44} width={44}/>
                        <Skeleton animation="wave" variant="text" height={'auto'} width={'83%'}/>
                    </div>
                    <Skeleton style={{ margin:'3px'}} animation="wave" variant="text" height={30} width={'40%'}/>
                    <Skeleton  animation="wave" variant="rect" height={180} width={'100%'}/>
                    <Skeleton  animation="wave" variant="rect" height={70} width={'100%'}/>
                    <Skeleton style={{ margin:'3px'}} animation="wave" variant="text" height={30} width={'98%'}/>
                </div>
                :
                <>
                    <CardHeader
                        title={title}
                        isFav={isFav}
                        createdDate={createdDate}
                        authorName={authorName}
                        handleFavorite={handleFavorite}
                    />
                    <CardMedia
                        className={classes.media}
                        image={image}
                    />
                    <CardContent className={classes.contentContainer}>
                        <Typography className="clampMe" variant="body2" color="textSecondary" component="p" paragraph={true}>
                            <Interweave content={description}/>
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing className={classes.bottomContainer}>
                        <Button onClick={handleClickReadMore} size="small">Read More</Button>
                        <CardActions className={classes.bottomButtons}>
                            <IconButton aria-label="edit blog" onClick={handleOpenEdit}>
                                <CreateIcon color='action' />
                            </IconButton>
                            <IconButton aria-label="delete blog" style={{margin:'0'}} onClick={handleDeleteBlog}>
                                <DeleteIcon  color='action'/>
                            </IconButton>
                        </CardActions>
                    </CardActions>
                </>
            }
            <ReadMoreDialog 
                open={openReadMore} 
                handleClose={handleCloseReadMore}
                title={title}
                description={description}
                image={image} 
                authorName={authorName}
                createdDate={createdDate}
            />
            <EditBlog open={openEdit} handleClose={handleCloseEdit} uid={uid}/>
            <DeleteBlogDialog open={open} handleClose={handleCloseDelete} uid={uid}/>
        </Card>
    );
}