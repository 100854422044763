import React, { useEffect, useContext, useState } from 'react';
import firebase from 'firebase/app'
import { Route, Switch } from 'react-router-dom'

import { firebaseConfig } from '../common/FirebaseConfig'
import { Context as AuthContext } from '../context/AuthContext';
import { Provider as VlogProvider } from '../context/BlogContext'
import Navbar from './Navbar';
import BlogsContainer from './BlogsContainer';
import LoginContainer from './LoginContainer';
import { blogAnalytics } from '../common/Analytics'

import Grid from '@material-ui/core/Grid'
import '../styles/app.css'
import ConnectionCheck from './ConnectionCheck';
import History from '../common/History';

blogAnalytics.init()
blogAnalytics.pageView(window.location.pathname + window.location.search)

const NonAuthRoutes = () => {
    return (
      <>
        <Route exact path="/" component={LoginContainer}/>
        <Route exact path="/login" component={LoginContainer} />
      </>
    )
}
const AuthRoutes = () => {
    return (
      <VlogProvider>
        <Route  path="/blogs" component={BlogsContainer}/>   
      </VlogProvider> 
    )
}

function App() {
  const {state} = useContext(AuthContext)
  const[authUser, setAuthUser] = useState(null)

  useEffect( ()=> {
    if(!firebase.apps.length){
      firebase.initializeApp(firebaseConfig)
      blogAnalytics.pageView('/login')
    }
    firebase.auth().onAuthStateChanged(
      authUser => {
        authUser
          ? setAuthUser(authUser.refreshToken)
          : setAuthUser(null)
      }
    )
    if(state && state?.user && state?.user?.user !== undefined ){
      blogAnalytics.setUser(state.user.user.uid )
    }
    if(authUser === JSON.parse( sessionStorage.getItem('token') || "{}").user?.stsTokenManager?.refreshToken){
      History.push('/blogs')
    }
  }, [state, authUser])

  return (
        <Grid container className="app-container">
          <Navbar authUser={authUser}/>
          <ConnectionCheck/>
          <Switch>
            { authUser === JSON.parse( sessionStorage.getItem('token') || "{}").user?.stsTokenManager?.refreshToken ? <AuthRoutes/> : <NonAuthRoutes/>}
          </Switch>
        </Grid>
  );
}

export default App
