import React from 'react'
import { Route } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import BlogTabs from './BlogTabs'
import BlogsList from './BlogsList'
import FavoriteBlogs from './FavoriteBlogs'
import ConnectionCheck from './ConnectionCheck'
import { blogAnalytics } from '../common/Analytics'

const useStyles = makeStyles((theme) => ({
    blogContainer : {
        padding:'44px 12px 24px 12px',
        width: '100%',
        overflowY: 'auto',
        overflowX:'unset', 
    },
    listContainer:{
        padding:'12px 12px'
    }
}))


function BlogsContainer(props){

    const classes = useStyles();

    blogAnalytics.pageView('/blogs')

    const renderViews = () => {
        return (
            <Grid item className={classes.listContainer}>
                {props.location.pathname === '/blogs' ? <BlogsList/> : null}
                <Route path="/blogs/favorites" render={ () => <FavoriteBlogs/>}/> 
            </Grid>
        )
    }
    return (
        <div className={classes.blogContainer} >
            <ConnectionCheck/>
            <Grid item>
                <BlogTabs/>
            </Grid>
            {renderViews()}
        </div>
    )
}

export default BlogsContainer
