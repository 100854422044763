import React from 'react'

const styles = {
    lineStyle : {
        width: '100%',
        border : '2px solid rgba(57,57,57,0.8)',
        marginBottom:'5px',
        zIndex:'2',
        position:'relative'
    }
}
export default function Lines() {
    return (
        <div style={styles.lineStyle}></div>
    )
}
