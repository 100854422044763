import React from 'react'
import { Grid, Button, withStyles,Typography, AppBar, Toolbar, makeStyles} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { orange } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ( {
    paymentContainer:{
        width:'100%',
        height:'100%',
        backgroundColor: '#fff3e0',
        border:'none',
        display: 'block'
    },
    paymentWrapper:{
        display:'flex',
        padding:'24px',
        flexDirection:'column'
    },
    buttonWrapper:{
        display:'flex',
        padding:'10px 0px',
        flexDirection:'row',
        justifyContent:'space-evenly'
    },
    buttonStyle:{
        width:'100%',
        height:'64px',
        margin:'0px 2px',
        fontSize:'30px',
    },
    appBar: {
        display:'flex',
        height:'60px',
        width:'100%',
        position: 'relative',
        backgroundColor:'#ff9800',
        color:'black'
    },
    title: {
        flex: 1,
    },
    donateFoooter:{
        width:'100%',
        height:'74px',
        display:'flex',
        flexDirection:'row',
        backgroundColor:'black',
        opacity:'0.8'
    },
    footerText:{
        display:'flex',
        width:'inherit',
        justifyContent:'center',
        alignItems:'center',
        color: 'white',
        textTransform:'full-width'
    }
}) )

const ColorButton = withStyles((theme) => ({
    root: {
        color: 'black',
        backgroundColor: orange[100],
        '&:hover': {
            backgroundColor: orange[200],
        },
    },
}))(Button);

const PaymentForm = ({ stripePromise, handlePaymentFormClose }) => {
    const classes = useStyles();

    const onhandleFiveClick = async (event) => {
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({
            lineItems: [
                {
                    price: process.env.REACT_APP_PAYMENT_5,
                    quantity: 1,
                },
        ],
            mode: 'payment',
            successUrl: window.location.href,
            cancelUrl: window.location.href,
        });
    };
    const onhandleTenClick = async (event) => {
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({
            lineItems: [
                {
                    price: process.env.REACT_APP_PAYMENT_10,
                    quantity: 1,
                },
        ],
            mode: 'payment',
            successUrl: window.location.href,
            cancelUrl: window.location.href,
        });
    };
    const onhandleFifteenClick = async (event) => {
        const stripe = await stripePromise;
        //const { error } = await ... 
        await stripe.redirectToCheckout({
            lineItems: [
                {
                    price: process.env.REACT_APP_PAYMENT_15,
                    quantity: 1,
                },
        ],
            mode: 'payment',
            successUrl: window.location.href,
            cancelUrl: window.location.href,
        });
    };
    return (
        <Grid
            container
            direction="column"
            justify="center"
            className={classes.paymentContainer}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h4" className={classes.title}>
                        Support Us
                    </Typography>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={handlePaymentFormClose}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.paymentWrapper}>
                <div>
                    <Typography variant="h5" className={classes.title}>
                        It is as simple as buying us a lunch.
                    </Typography>
                </div>
                <div className={classes.buttonWrapper}>
                    <ColorButton autoFocus variant="outlined" color="primary" className={classes.buttonStyle} onClick={onhandleFiveClick}>
                        $5
                    </ColorButton>
                    <ColorButton autoFocus variant="outlined" color="primary" className={classes.buttonStyle} onClick={onhandleTenClick}>
                        $10
                    </ColorButton>
                    <ColorButton autoFocus variant="outlined" color="primary" className={classes.buttonStyle} onClick={onhandleFifteenClick}>
                        $15
                    </ColorButton>
                </div>
            </div>
            <div className={classes.donateFoooter}>
                <Typography variant="h5" className={classes.footerText}>
                    THANK YOU!!
                </Typography>
            </div>
        </Grid>
    );
};

export default PaymentForm;