import React from 'react'
import '../styles/header.css'
import logo from '../assets/BMLogo.png';


export default function Header( {label, textAlign, showLogo=false} ) {
    return (
            <div className="label-size" style={{textAlign, display:'flex', alignItems:'center' }} >
                { showLogo && <img src={logo} width={70} height={70} alt='blogs-manager-logo'/>}
                {showLogo ? <div className='label-wrap'>{label}</div> : <div>{label}</div>}
            </div>
    )
}
