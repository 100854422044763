import _ from 'lodash'
import firebase from 'firebase'
import createDataContext from './createDataContext';
import History from '../common/History'
import * as defaultImage from '../assets/defaultImage.jpg'
import { blogAnalytics } from '../common/Analytics';

const INITIAL_STATE = { 
    isError: false,
    blogDetails: [],
    isDonationSuccessful: false
}

const blogReducer = ( state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'get_blogposts':
            return { ...INITIAL_STATE, blogDetails: action.payload }
        case 'add_blogpost':
            return [...INITIAL_STATE]
        case 'edit_blogpost':
            return state 
        case 'delete_blogpost':
            return state
        case 'set_donation':
            return {...INITIAL_STATE, isDonationSuccessful: action.payload}
        default:
            return state;
    }
};

export const setDonationSuccessfull = (dispatch) => {
    return () => {
        dispatch({ type:'set_donation', payload : true })
    }
}

export const getBlogPost = (dispatch) => {
    const { currentUser } = firebase.auth()

    return () => {
        firebase.database().ref(`/users/${currentUser.uid}/vlogs`)
            .on('value', snapshot => {
                const result =  _.map(snapshot.val(), (val, uid) => {
                    return { ...val, uid }
                })
                dispatch({ type:'get_blogposts', payload : result})
                blogAnalytics.trackEvent("NUMBER OF BLOGS", "No. of blogs renderred", `TOTAL_BLOGS: ${result?.length}`)
            })
        History.push('/blogs')
    }
}

export const addBlogPost = dispatch => {
    return ( title, img, description, authorName ) => {
        let createdDate = new Date().toDateString()
        if( img.length === 0){
            srcToFileConverter( defaultImage, 'default.jpg', 'image/jpeg').then((file)=>{
                toPostImage(dispatch, createdDate,title, file, description, authorName )
            })
        }else{
            toPostImage(dispatch, createdDate, title, img, description, authorName )
        }
    };
};

async function toPostImage(dispatch, createdDate, title, img, description, authorName ){
    try {
        const { currentUser } = firebase.auth()

        await firebase.storage().ref(`images/${img.name}`).put(img);
        await firebase.storage()
                .ref("images")
                .child(img.name)
                .getDownloadURL()
                .then(url => {
                    firebase.database().ref(`/users/${currentUser.uid}/vlogs`)
                        .push({ createdDate , title, url, description, authorName, imageName: img.name})
                        .then(() => {
                            dispatch( { type: 'add_blogposts' } )
                        } )
                });
        blogAnalytics.trackEvent("BLOG", "Added new blog", "NEW_BLOG_POST")
    } catch (error) {
        console.log(error, 'ERROR')
    }
    
}

export const favoriteBlog = dispatch => {
    const { currentUser } = firebase.auth()
    return async ({ uid, isFavorite }) => {
        await firebase.database().ref(`/users/${currentUser.uid}/vlogs/${uid}`).update({isFavorite})
        dispatch({ type:'favorite_blog' })
    }
}

export const deleteBlogPost = ( dispatch ) => {
    const { currentUser }  = firebase.auth()
    return async ( { uid } ) => {
        const response = await firebase.database().ref(`/users/${currentUser.uid}/vlogs/${uid}`)
        response.on('value', async snapshot => {
            if(snapshot.val() !== null ){
                await firebase.storage().ref('images').child(snapshot.val().imageName).delete()
            }
        })
        
        await response.remove()
                .then(() => {
                    dispatch( { type: 'delete_blogposts' } )
                } )
        blogAnalytics.trackEvent("BLOG DELETE", "Blog deleted", "BLOG_DELETE")
    }
}

export const editBlogPost = dispatch => {
    const { currentUser }  = firebase.auth()

    return async ( title, image, description, authorName, uid, isFavorite) => {
        let createdDate = new Date().toDateString()
        if(typeof image ===  'object' ){
            await firebase.storage().ref(`images/${image.name}`).put(image);
            await firebase.storage()
                    .ref("images")
                    .child(image.name)
                    .getDownloadURL()
                    .then(url => {
                        firebase.database().ref(`/users/${currentUser.uid}/vlogs/${uid}`)
                        .set({ createdDate, title,url, description, authorName, imageName: image.name, isFavorite})
                        .then(() => {
                            dispatch({ 
                                type: 'edit_blogpost', 
                            });
                        })
                    })
        }else{
            firebase.database().ref(`/users/${currentUser.uid}/vlogs/${uid}`)
            .set({ createdDate, title, url : image, description, authorName, isFavorite})
            .then(() => {
                dispatch({ 
                    type: 'edit_blogpost', 
                });
            })
        }
    };
}

function srcToFileConverter( src, fileName, mimeType ){
    return (fetch(src)
        .then(function(res){
            return res.arrayBuffer();
        })
        .then(function(buf){
            return new File([buf], fileName, { type:mimeType });
        })
    );
}




export const { Context, Provider } = createDataContext(
    blogReducer,
    { getBlogPost, addBlogPost , deleteBlogPost, editBlogPost, favoriteBlog, setDonationSuccessfull },
    INITIAL_STATE
);