import React from 'react'
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: '280px',
        position:'absolute',
        zIndex:'10',
        top:'68px'
    },
    alertWrapper:{
        width:'100%',
        height:'38px'
    }
}));

export default function ToastMessage({ isSuccess }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {
                isSuccess ?         
                <Alert variant="filled" severity="success" className={classes.alertWrapper}>
                    This is a error alert — check it out!
                </Alert> : null
            }
        </div>
    )
}

