
import React,{ useContext, useEffect, Fragment, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import { blogAnalytics } from '../common/Analytics'
import { Context as BlogContext } from '../context/BlogContext'
import BlogContent from './BlogContent';
import NoBlogText from '../common/NoBlogText';
import Portal from '../common/Portal';


const useStyles = makeStyles((theme) => ({
    gridContainerStyle:{
        display: 'flex' , 
        justifyContent:'space-evenly'
    }
}))

export default function FavoriteBlogs() {
    const classes = useStyles()
    const { state } = useContext(BlogContext)
    const [isLoading , setIsLoading ] = useState(true)
    const [onlyFav, setOnlyFav] = useState([])

    blogAnalytics.pageView('/blogs/favorites')

    useEffect(()=>{
        const showOnlyFavorites = () => {
            const result = state?.blogDetails.filter((blog) => blog.isFavorite)
            setOnlyFav(result)
        }
        showOnlyFavorites()
        setTimeout(()=>{
            setIsLoading(false)
        }, 3000)
    },[state?.blogDetails])// eslint-disable-line react-hooks/exhaustive-deps

    const renderList = () => {
        if(onlyFav.length > 0 ){
            return onlyFav.map((blog, index) =>{
                return <Fragment key={index}>
                        <BlogContent 
                            title={blog.title}
                            image={blog.url}
                            description={blog.description}
                            authorName={blog.authorName}
                            uid={blog.uid}
                            createdDate={blog.createdDate}
                            isFav={blog.isFavorite || false }
                            isLoading={isLoading}
                        />
                    </Fragment>
            })
        }else if(onlyFav.length === 0 && !isLoading ) {
            return <Portal isOpen={true}><NoBlogText header="favorite"/></Portal> 
        }
    }
    return (
        <Grid container item className={classes.gridContainerStyle}>
            {renderList()}
        </Grid>
    )
}
