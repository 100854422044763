import React, { useContext } from 'react';
import {Button, Dialog, DialogActions, DialogTitle, Slide} from '@material-ui/core';
import { Context as BlogContext } from '../context/BlogContext'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteBlogDialog({open, handleClose,uid}) {
    const { deleteBlogPost } = useContext(BlogContext)

    const onHandleDelete = () => {
        deleteBlogPost({uid})
        handleClose()
    }
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Are you sure you want to delete this blog?</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose} color="default">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={onHandleDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}