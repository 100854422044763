import React from 'react'
import ReactDom from 'react-dom'

const portalRoot = document.querySelector( '#portal' )

const Portal = ({children, isOpen}) => {
    
    return (
            isOpen && ReactDom.createPortal(
                    <div style={{display:'flex', height:'100%',justifyContent:'center'}}>
                        {children}
                    </div>
            , portalRoot )
        )
}

export default Portal 