import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Interweave } from 'interweave';
import { Button, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CardHeader} from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
    root:{
        overflowY:'auto',
    },
    title:{
        padding:'10px 16px 0px 16px',
        fontSize:'32px'
    },
    description:{
        overflow:'unset',
    },
    headerStyle:{
        fontSize:'8px'
    },
    media: {
        height: 'auto',
        width:'100%',
        paddingTop: '36.25%',
    },
}));

export default function ReadMoreDialog({open, handleClose, title, description, image, authorName, createdDate}) {
    const classes = useStyles();

return (
    <React.Fragment>
        <Dialog
            fullWidth
            maxWidth='lg'
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            className={classes.root}
        >
            <CardMedia
                className={classes.media}
                image={image}
            />
            <DialogTitle disableTypography className={classes.title} id="max-width-dialog-title">
                {title}
            </DialogTitle>
            <CardHeader className={classes.headerStyle} title={`By ${authorName}`} subheader={createdDate}/>
            <DialogContent className={classes.description}>
                <DialogContentText>
                    <Interweave content={description}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
    );
}