import React,{ useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    gridContainer:{
        width:'100%',
        height:'100%', 
        backgroundColor:'#fff3e0',
        overflowY:'auto'
    },
    gridItem:{
        padding:'6px 24px'
    },
    appBar: {
        position: 'relative',
        backgroundColor:'#ff9800',
        color:'black'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TextFieldForm = withStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
            color: 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
            },
            '&:hover fieldset': {
                borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
    },
}))(TextField);

export default function BlogPostForm({blogPost, open, handleClose, onSubmit, uid, initialValues, header}) {
    const classes = useStyles();
    const [title, setTitle] = useState(blogPost && blogPost.title.length > 0 ? blogPost.title :'')
    const [image, setImage] = useState(blogPost && blogPost.url.length > 0 ? blogPost.url :'')
    const [description, setDescription] = useState(blogPost && blogPost.description.length > 0 ? blogPost.description :'')
    const [authorName, setAuthorName] = useState(blogPost && blogPost.authorName.length > 0 ? blogPost.authorName :'')

    const handleSubmit = () => {
        onSubmit(title, image, description, authorName, uid, initialValues.isFavorite)
        handleClose()
    }
    const resetForm = () => {
        setTitle('')
        setAuthorName('')
        setDescription('')
        setImage('')
    }
    return (
        <div>
            <Dialog fullScreen open={open} onClose={() => { resetForm(); handleClose(); }} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => { resetForm(); handleClose(); }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {header}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleSubmit} disabled={title === '' || description === '' || authorName === ''}>
                            Submit
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.gridContainer}>
                    <Grid item className={classes.gridItem}>
                        <TextFieldForm
                            id="outlined-full-width-1"
                            label="Title"
                            placeholder="eg. How to think positive?"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            type="text"
                            required
                            onChange={(event) => setTitle(event.target.value)}
                            value={title}
                        />
                    </Grid>
                    <Grid container>
                        <Grid xs={6} sm={6} md={6} lg={6} xl={6} item className={classes.gridItem}>
                            <TextFieldForm
                                id="outlined-full-width-2"
                                label="Author's Name"
                                placeholder="John Doe"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                type="text"
                                required
                                onChange={(event) => setAuthorName(event.target.value)}
                                value={authorName}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  className={classes.gridItem}>
                            <TextFieldForm
                                id="outlined-full-width-3"
                                label="Upload Image"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                type="file"
                                required
                                title="pic"
                                onChange={(event) => setImage(event.target.files[0])}
                            />
                        </Grid>
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <TextFieldForm
                            id="outlined-full-width-4"
                            label="Description"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            type="text"
                            required
                            multiline
                            onChange={(event) => setDescription(event.target.value)}
                            value={description}
                        />
                    </Grid>
                </div>
            </Dialog>
        </div>
    );
}

BlogPostForm.defaultProps = {
    initialValues : {
        title : '',
        image : '',
        description:'',
        authorName:''
    }
}