import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    paperWidthSm:{
        width: '30%',
        height: '25vh',
        borderTop:`3px solid ${orange[500]}`,
        backgroundColor:"white",
        position: 'absolute',
        top:'40%',
        display:'flex',
        boxShadow: '5px 8px 10px 2px rgba(0, 0, 0, .5)',
        borderRadius: '15px'
    },
    NoFav:{
        margin:'auto',
        textTransform:'uppercase',
        backgroundColor:'black',
        '-webkitBackgroundClip':'text',
        color:'transparent',
        opacity:'0.75',
        textAlign: 'center'
    },
    textStyle:{
        fontSize: "calc(16px + 6 * ((100vw - 320px) / 680) )",
        '-youbkitTouchCallout': 'none', /* iOS Safari */
        '-youbkitUserSelect': 'none',   /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
        '-mozUserSelect': 'none',      /* Firefox */
        '-msUserSelect': 'none',        /* IE 10+ and Edge */
        userSelect: 'none',
    }
}));

const NoBlogText = ({ header }) => {
    const classes = useStyles()

    return (
        <div className={classes.paperWidthSm}>
            <div className={classes.NoFav}>
                <div className={classes.textStyle}>Oops !! No {header} are available</div>
            </div>
        </div>
    )
}

export default NoBlogText
