import React, { useContext } from 'react'
import { Context as BlogContext } from '../context/BlogContext'
import BlogPostForm from './BlogPostForm'

export default function EditBlog({open, handleClose, uid}) {
    const { state, editBlogPost }  = useContext(BlogContext)

    const vlogPost = state?.blogDetails.find(vlog => vlog.uid === uid )

    return open && <BlogPostForm
                initialValues={{ isFavorite : vlogPost.isFavorite  }}
                state={state}
                vlogPost={vlogPost}
                open={open} 
                handleClose={handleClose}
                onSubmit={editBlogPost}
                uid={uid}
                header="Edit Blog"
        />
}
