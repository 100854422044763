import React, {useContext, useState} from 'react'
import Header from '../common/Header'
import { TextField, Grid, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Context as AuthContext } from '../context/AuthContext'
import { orange, grey } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    buttonStyle: {
        margin: theme.spacing(1),
        width:'100%',
        padding:'8px !important'
    },
    gridStyle:{
        padding:'8px !important'
    },
    buttonWrapper:{
        display:'flex',
        flexDirection:'row',
        padding:'8px !important'
    },
    textStyle:{
        display:'flex',
        width:'50%'
    }
}));

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(orange[200]),
        backgroundColor: orange[200],
        '&:hover': {
            backgroundColor: orange[200],
        },
    },
}))(Button);
const TextFieldForm = withStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
            color: 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
            },
            '&:hover fieldset': {
                borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
    },
}))(TextField);

const BackButton = withStyles((theme) => ({
    root: {
        color: 'white',
        backgroundColor: grey[500],
        '&:hover': {
            backgroundColor: grey[900],
        },
    },
}))(Button);

export default function ForgotPassword({cancel}) {
    const classes = useStyles();
    const [showMessage, setShowMessage] = useState(false)
    const { state: { email }, emailChanged, forgotPassword } = useContext(AuthContext)
    const emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i

    const onEmailChange = (event) => {
        emailChanged(event.target.value)
    }
    const onForgotSubmit = () => {
        setShowMessage(true)
        forgotPassword(email)
    }

    return (
        <Grid 
            container
            spacing={4}
            direction="column"
            alignContent="center"
            style={{margin:'10% 0% 0% 0%'}}
        >
            <Grid item className={classes.gridStyle}>
                <Header label="Forgot your password?" />
            </Grid>
            {
                !showMessage ? 
                    <Grid item className={classes.gridStyle}>
                        <TextFieldForm
                            id="outlined-full-width-1"
                            label="Email"
                            style={{ margin: 2 }}
                            placeholder="Enter your email"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={onEmailChange}
                            value={email || ''}
                            type="email"
                            required
                            helperText={email && email.length > 0 && email.match(emailPattern) ? null :"Please enter your valid email"}
                        />
                    </Grid> :
                    <Grid item className={classes.textStyle}>
                        If the email address you entered, {email}, is associated with an account in our records, you will receive an email from us with instructions for resetting your password. If you do not receive this email, please check your junk mail folder.
                    </Grid>
            }
            {
                showMessage ? 
                <Grid item className={classes.buttonWrapper}>
                    <BackButton 
                        variant="contained" 
                        // color="primary"
                        disableElevation
                        className={classes.buttonStyle} 
                        onClick={cancel}
                    >
                        Back
                    </BackButton>
                </Grid>
                :
                <Grid item className={classes.buttonWrapper}>
                    <ColorButton 
                        variant="contained" 
                        color="primary" 
                        className={classes.buttonStyle} 
                        onClick={onForgotSubmit}
                        disabled={(email === undefined || !email.match(emailPattern) )}
                    >
                        Submit
                    </ColorButton>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        className={classes.buttonStyle} 
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                </Grid>
            }
        </Grid>
    )
}
