import React, {useState, useContext, Fragment, useEffect} from 'react';

import History from '../common/History';
import { Context as BlogContext } from '../context/BlogContext'
import CreateBlog from './CreateBlog';

import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ViewListIcon from '@material-ui/icons/ViewList';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddIcon from '@material-ui/icons/Add';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { orange } from '@material-ui/core/colors';
import { blogAnalytics } from '../common/Analytics';

const useStyles = makeStyles({
    root: {
        width: '35%',
        backgroundColor: 'transparent',
        margin:'10px auto auto'
    },
    label:{
        '&$selected': {
            color: orange[600],
        },
    },
    selected: {},
});

export default function BlogTabs( props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [newBlogType, setNewBlogType] = useState('');
    const {getBlogPost } = useContext(BlogContext)

    const handleOpen = (type) => {
        setOpen(true);
        setNewBlogType(type)
        blogAnalytics.trackEvent("NEW BLOG", "Initiated new blogpost", "NEW_BLOG_POST")
    };

    const handleClose = () => {
        setOpen(false);
        setValue(0)
        History.push('/blogs')
        blogAnalytics.trackEvent("NEW BLOG", "Discarded new blogpost", "NEW_BLOG_POST")
    };

    const handleFavs = () => {
        History.push('/blogs/favorites')
    }

    useEffect(()=>{
        if(History.location.pathname === '/blogs'){
            setValue(0)
        }
    },[History.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                className={classes.root}
            >
                <BottomNavigationAction 
                    label="Blogs" 
                    onClick={getBlogPost} 
                    classes={classes} 
                    icon={<ViewListIcon style={{color: (value === 0) ? orange[600] : '' }} />} 
                />
                <BottomNavigationAction 
                    label="Add New"
                    onClick={() => handleOpen('create_new')} 
                    classes={classes} 
                    icon={<AddIcon style={{color: (value === 1) ? orange[600] : '' }}/>}
                />
                <BottomNavigationAction 
                    label="Favorites" 
                    onClick={handleFavs} 
                    classes={classes} 
                    icon={<FavoriteIcon  style={{color: (value === 2) ? orange[600] : '' }}/>} 
                />
                <BottomNavigationAction 
                    label="Try with AI" 
                    onClick={() => handleOpen('create_new_with_ai')} 
                    classes={classes} 
                    icon={<QuestionAnswerIcon  style={{color: (value === 3) ? orange[600] : '' }}/>}
                />

            </BottomNavigation>
           {open && <CreateBlog open={open} handleClose={handleClose} newBlogType={newBlogType}/> }
        </Fragment>

    );
}