import firebase from 'firebase'
import { blogAnalytics } from '../common/Analytics'
import History from '../common/History'

import createDataContext from './createDataContext'
const INITIAL_STATE = { email: '', password: '', user : null, error: '', loading: false }


const authReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case 'email_changed':
            return { ...state, email: action.payload }
        case 'password_changed':
            return { ...state, password: action.payload }
        case 'login_user':
            return { ...state, loading: true, error: '' }
        case 'login_user_success':
            sessionStorage.setItem('token', JSON.stringify(action.payload) );
            return { ...state, ...INITIAL_STATE, user: action.payload}
        case 'login_user_fail':
            return { ...state, error: 'Authentication Failed.', password: '', loading: false }
        case 'logout_user':
            sessionStorage.removeItem('token');
            return { ...INITIAL_STATE }
        default:
            return state
    }
}

export const emailChanged = (dispatch) =>  {
    return (text) => {
        dispatch({
            type: 'email_changed',
            payload: text 
        })
    }
}

export const passwordChanged = (dispatch) => {
    return (pass) => {
        dispatch({
            type: 'password_changed',
            payload: pass
        })
    }
}

export const loginUser = ( dispatch ) => {
    return ({ email, password }) => {
        dispatch({type: 'login_user'})
        blogAnalytics.trackEvent("LOGIN USER","Logged in user with email",`LOGIN_USER`)
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(user => loginUserSuccess(dispatch, user))
            .catch((error)=>{
                firebase.auth().createUserWithEmailAndPassword(email, password)
                    .then(user => loginUserSuccess(dispatch, user))
                    .catch(()=> loginUserFail(dispatch))
            })
    }
}
export const logoutUser = ( dispatch ) => {
    return () => {
        dispatch({type: 'logout_user'})
        firebase.auth().signOut()
        blogAnalytics.trackEvent("LOGOUT USER","Logged out user",`LOGOUT_USER`)
        History.push('/login')
    }
}
export const signInWithGoogle = (dispatch) => {
    return () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        blogAnalytics.trackEvent("SSO USER","Logged in user with Gmail",`SSO_USER`)
        firebase.auth().signInWithPopup(provider)
            .then(user => loginUserSuccess(dispatch, user))
            .catch(()=>loginUserFail(dispatch))
    }
}

export const forgotPassword = () => {
    return (email) => {
        firebase.auth().sendPasswordResetEmail(email)
    }
}

const loginUserFail = ( dispatch) => {
    dispatch( { type: 'login_user_fail' } )
    History.push('/login')
}

const loginUserSuccess = ( dispatch, user ) => {
    dispatch( { type: 'login_user_success', payload: user} )
    History.push('/blogs')
}
export const { Context, Provider } = createDataContext(
    authReducer,
    { emailChanged, passwordChanged, loginUser, logoutUser, signInWithGoogle, forgotPassword },
    {}
);
