import React from 'react'
import { Avatar, IconButton } from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite';
import { orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import '../styles/cardHeader.css'


const useStyles = makeStyles((theme) => ({
    iconStyle:{
        padding:'5px 5px'
    },
    avatar: {
        backgroundColor: orange[500],
    },
    headerStyle:{
        width: 'auto',
        display : 'flex',
    },
    titleStyle:{
        margin:'auto',
        padding:'0px 5px',
    },
    createdDateStyle:{
        display: 'flex',
        padding: '2px 5px 10px 5px',
        fontSize: '.85em',
        opacity: 0.8,
    }

}));

export default function CardHeader({ title, isFav, authorName, createdDate, handleFavorite}) {
    const classes = useStyles();

    return (
        <>
            <div className={classes.headerStyle}>
                <div className={classes.iconStyle}>
                    <Avatar aria-label="user" className={classes.avatar}> {authorName[0].toUpperCase() || 'B'} </Avatar>
                </div>
                <div className={`${classes.titleStyle} clampTitleText`}>
                    {title}
                </div>
                <div>
                    <IconButton aria-label="add to favorites" onClick={handleFavorite}>
                        {isFav ? <FavoriteIcon style={{color:'red'}} /> : <FavoriteIcon color='action'/>}
                    </IconButton>
                </div>
            </div>
            <div className={classes.createdDateStyle}>
                {createdDate}
            </div>
        </>
    )
}
