import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, Grid, Button, CircularProgress, Typography } from '@material-ui/core';
import { orange, deepOrange } from '@material-ui/core/colors';

import { Context as AuthContext } from '../context/AuthContext'

import Header from '../common/Header';
import Lines from '../common/Lines';

const useStyles = makeStyles((theme) => ({
    root: {
        padding:'95px 0px 0px 0px'
    },
    buttonStyle: {
        margin: theme.spacing(1),
        width:'100%',
        padding:'8px !important'
    },
    spinner:{
        color: 'rgba(0,0,0,0.8)'
    },
    gridStyle:{
        padding:'8px !important'
    },
    forgotStyle:{
        margin: theme.spacing(1),
        width:'100%',
        padding:'1px !important'
    },
    orTextStyle:{
        margin:'auto', 
        padding:'0px',
        position:'relative',
        zIndex:'2'
    }
}));

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(orange[200]),
        backgroundColor: orange[200],
        '&:hover': {
            backgroundColor: orange[200],
        },
    },
}))(Button);


const GoogleButton = withStyles((theme) => ({
    root: {
        color: 'white',
        backgroundColor: deepOrange[800],
        '&:hover': {
            backgroundColor: deepOrange[900],
        },
    },
}))(Button);

const TextFieldForm = withStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
            color: 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
            },
            '&:hover fieldset': {
                borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
    },
}))(TextField);

export default function LoginForm({forgotPassHandler}) {
    const classes = useStyles();
    const emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    const passPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
    const { state: {email, password, error, loading }, emailChanged, passwordChanged, loginUser, signInWithGoogle } = useContext(AuthContext)

    const onEmailChange = (event) => {
        emailChanged(event.target.value)
    }

    const onPasswordChange = ( event ) => {
        passwordChanged(event.target.value)
    }

    const onLogin = () => {
        loginUser({ email : email.toLowerCase(), password })
    }

    const renderButton = () => {
        if(loading){
            return <div style={{textAlign:"center"}}><CircularProgress className={classes.spinner}/></div>
        }
        return <ColorButton 
                    
                    variant="contained" 
                    color="primary" 
                    className={classes.buttonStyle} 
                    onClick={onLogin} 
                    disabled={(email === undefined || password === undefined || !email.match(emailPattern) || !password.match(passPattern) )}
                >
                    Sign up or Login
                </ColorButton>
    }

    const renderError = () => {
        if( error ){
            return <div style={{textAlign:'center', color: 'red'}}>{error}</div>
        }
    }

    const passInstructions = () => {
        let instructions = [
            'Password must contain at least 8 characters',
            'at least 1 numeric character','at least 1 lowercase letter',
            'at least 1 uppercase letter',
            'at least 1 special character'
        ]
        return instructions.map((instruction,index) => {
            return (
                    <Typography key={index} style={{margin:'0', padding:'0'}} variant="caption" color="textSecondary" component="span">
                        {instruction} <br/>
                    </Typography>
            )
        })
    }

    return (
        <Grid
            container
            direction="column"
            alignContent="center"
            className={classes.root}
        >
            <Grid item className={classes.gridStyle}>
                <Lines/>
                <Lines/>
            </Grid>
        

            <Grid item className={classes.gridStyle} >
                <Header label="Login to Blogsmanager" textAlign="center"/>
            </Grid>
            <Grid item className={classes.gridStyle} >
 
                <TextFieldForm
                    id="outlined-full-width-1"
                    label="Email"
                    style={{ margin: 2 }}
                    placeholder="john@email.com"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    onChange={onEmailChange}
                    value={email || ''}
                    type="email"
                    required
                    helperText={email && email.length > 0 && email.match(emailPattern) ? null :"Please enter your valid email"}
                />
            </Grid>
            <Grid item className={classes.gridStyle} >
                        <TextFieldForm
                            id="outlined-full-width-2"
                            label="Password"
                            style={{ margin: 2 }}
                            placeholder="password"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={onPasswordChange}
                            value={password || ''}
                            type="password"
                            required
                            helperText={password && password.length > 0 && password.match(passPattern) ? null: passInstructions()}
                        />              
            </Grid>
                {renderError()}
            <Grid item className={classes.gridStyle}>
                {renderButton()}
            </Grid>
            <Grid item className={classes.orTextStyle}>
                or
            </Grid>
            <Grid item className={classes.gridStyle}>
                <GoogleButton 
                    className={classes.buttonStyle}
                    variant="contained" 
                    onClick={signInWithGoogle} 
                >
                    Login With Google
                </GoogleButton> 
            </Grid>
            <Grid item style={{padding:'0'}}>
                <Button className={classes.forgotStyle} onClick={forgotPassHandler}>Forgot your password?</Button>
            </Grid>
            <Grid item className={classes.gridStyle}>
                <Lines/>
                <Lines/>
            </Grid>
        </Grid>
    );
}