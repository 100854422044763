import React, { useState } from 'react'
import LoginForm from './LoginFrom'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ForgotPassword from './ForgotPassword';
import '../styles/app.css'

const useStyles = makeStyles((theme) => ({
    loginContainer : { 
        overflowY:'auto', 
        overflowX:'unset',
        height:'100vh', 
        width :'100%'
    },
}))


export default function LoginContainer() {
    const classes = useStyles();

    const [forgotPass, setForgotPass] = useState(false)
    const forgotPassHandler = () => {
        setForgotPass(true)
    }

    return (
        <div className="content-container">
            <Grid container direction="column" className={classes.loginContainer} >
                { forgotPass  ?  <ForgotPassword cancel={()=>setForgotPass(false)}/>  :  <LoginForm forgotPassHandler={forgotPassHandler} />}   
            </Grid>
        </div>

    )
}
