import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import Portal from '../common/Portal'

const useStyles = makeStyles((theme) => ({
    paperWidthSm:{
        width: '100%',
        height: '40px',
        backgroundColor:"red",
        position: 'absolute',
        top:'9%',
        display:'flex',
        boxShadow: '5px 8px 10px 2px rgba(0, 0, 0, .5)',
        zIndex:1,
        color:'white',
        fontSize:'1em',
        fontWeight:'bold'
    },
    errorWrapper:{
        display:'flex',
        margin:'auto'
    },
    errorDescription:{
        padding:"3px"
    }
}));

const ConnectionCheck = () => {
    const classes = useStyles()
    const [connected, setConnected] = useState(window.navigator.onLine)

    useEffect( ()=> {
        setConnected(window.navigator.onLine)
    },[window.navigator.onLine]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {connected === false ?
                <Portal isOpen={true}>
                    <div className={classes.paperWidthSm}>
                        <div className={classes.errorWrapper}>
                            <ErrorIcon fontSize="12"/>
                            <div className={classes.errorDescription}>
                                Network error. Please try again
                            </div>
                        </div>
                    </div>
                </Portal> : null
            }
        </>
    )
}

export default ConnectionCheck
