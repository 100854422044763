import React, {useState, useContext } from 'react'
import { Grid, Button, Typography, AppBar, Toolbar, Modal} from '@material-ui/core'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Header from '../common/Header'
import { Context as AuthContext } from '../context/AuthContext'

import { makeStyles } from '@material-ui/core/styles';
import PaymentForm from '../common/PaymentForm';

import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ID);
const useStyles = makeStyles((theme) => ({
    navbarWrapper:{
        height: '68px',
        position:'fixed',
        overflow:'hidden',
        width:'100%',
        zIndex: '10',
        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.5)'
    },
    appBar: {
        position: 'relative',
        backgroundColor:'rgba(0,0,0,0.9)',
        color:'white'
    },
    title: {
        flex: 1,
    },
    button: {
        backgroundColor: 'rgba(0,0,0,0.9)',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
            color: 'black',
        }
    },
    modalBoxWrapper: {
        position: 'absolute !important',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: '330px',
        backgroundColor: 'white',
        boxShadow: 24,
        p: 4,
    }
}));


const Navbar = ({ authUser }) => {
    const classes = useStyles();
    const { logoutUser } = useContext(AuthContext) 
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                className={classes.navbarWrapper}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.title}>
                            <Header
                                label="Blogs Manager"
                                textAlign='left'
                                showLogo={true}
                            />
                        </Typography>
                        { authUser ? 
                            <>
                                <Button autoFocus color="inherit" className={classes.button} onClick={handleOpen}>
                                    <MonetizationOnIcon fontSize="medium"/>
                                </Button> 
                                <Button autoFocus color="inherit"  className={classes.button} onClick={logoutUser}>
                                    Logout
                                </Button> 
                            </> : null
                        }
                    </Toolbar>
                </AppBar>
            
            </Grid>
            {
                open ? 
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                    <div className={classes.modalBoxWrapper}>
                        <PaymentForm 
                            stripePromise={stripePromise}
                            handlePaymentFormClose={handleClose}
                        />
                    </div>
                </Modal>: null
            }
        </>
    )
}

export default Navbar
