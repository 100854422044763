import ReactGA from 'react-ga';

export const blogAnalytics = {
    init: () => {
        ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
    },
    setUser: ( user ) => {
        ReactGA.set({
            userId: user
        })
    },
    pageView : ( path ) => {
        ReactGA.pageview(path)
    },
    trackEvent: (category, action, label) => {
        ReactGA.event({ category, action, label });
    }
    
}
